import { Link } from "react-router-dom";

import '../../../component_styles/landing/homepage/bottom_bar.css'

import { 
    FacebookIcon, 
    InstagramIcon, 
    RedHeart, 
    TwitterIcon, 
    YoutubeIcon, 
} from "../SVGDump";


const BottomBar = () => (
    <footer>
        <div className="footer-sub">
            {/*red-heart*/}
            <div className="red-heart">
                <RedHeart/>
            </div>
            {/*trademark name*/}
            <div className="title">
                American Native Heart Foundation
            </div>
            {/*footer nav*/}
            <div className="footer-nav">
                {/*footer sub nav*/}  
                <div className="footer-sub-nav">
                    {/*site links*/}
                    <div className="footer-sub-nav-site-links">
                        <div className="footer-site-links">
                            <Link to="/donate-now">
                                Donation
                            </Link>
                        </div>
                        <div className="footer-site-links">
                            <Link to="/about">
                                About
                            </Link>
                        </div>
                        <div className="footer-site-links">
                            <Link to="/request-help">
                                Request help
                            </Link>
                        </div>
                        <div className="footer-site-links">
                            <Link to="/dashboard">
                                Join us
                            </Link>
                        </div>
                    </div>
                    {/*socials*/}
                    <div className="socials">   
                        <span className="twitter">
                            <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                                <TwitterIcon />
                            </a>
                        </span>
                        <span className="facebook">
                            <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                                <FacebookIcon />
                            </a>
                        </span>
                        <span className="youtube">
                            <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                                <YoutubeIcon />
                            </a>
                        </span>
                        <span className="instagram">
                            <a href="https://www.instagram.com/native_heart_foundation?igsh=dnJvc2kyY2ZpZ3ow">
                                <InstagramIcon />
                            </a>
                        </span>
                    </div>
                    {/*address mobile*/}
                    <div className="address-mobile">
                        <div className="mobile">
                            +1 747-231-0036
                        </div>
                        <div className="address">
                            W Hillwood Path, Pine Ridge,<br/>
                            FL 34465, USA
                        </div>         
                    </div>
                </div>
                {/*contact note*/}
                <div className="contact-note">
                    <p>
                        Behind this link lies the chance to connect with us, to share your story, your vision, or your question. Let's build something meaningful together.
                    </p>
                    <div className="footer-site-links">
                        <a href="mailto:support@americannativeheartfoundation.com">
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
            {/*copyright info*/}
            <div className="copyright-info">
                © 2023 Shadient.co. All rights reserved.
            </div>
        </div>
    </footer>
)

export default BottomBar