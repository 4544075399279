import React from 'react';

import '../../../component_styles/landing/homepage/assistance_section.css'

const AssistanceSection = () => (
    <section className="assistance">
        <div className="assistance-heading-wrapper">
            <div className="assistance-heading heading-text">
                Request Assistance for a Cause
            </div>
            <div>
                <div className="assistance-note">
                    If you or someone you know is facing challenges and could use a helping hand, 
                    please feel free to request assistance. Our community is here to support each other, 
                    and we believe in the power of kindness. Share your story, and let us come together to 
                    create positive change. Your request matters, and we're here to help.
                </div>
                <button className="donate-button butt" id="assist-butt">
                    <a href="request-help.html">
                        <div className="inner-butt">
                            <span>Request Help</span>
                        </div>
                    </a>
                </button>
            </div>
        </div>
        <div className="assistance-img">
            <img
                src="https://res.cloudinary.com/derzcse6f/image/upload/v1732880240/nathan-anderson-GM5Yn5XRVqA-unsplash_d7lypt.jpg"
                alt="Family smiling"
                style={{objectFit: "cover"}}
            />
        </div>
    </section>
)

export default AssistanceSection