import { useEffect } from "react";
import { WhiteStar } from "../SVGDump"

// import styles
import "../../../component_styles/landing/homepage/tfs.css"

const TfsSection = () => {

    useEffect(()=>{
        const wW=window.innerWidth
        const tfsEntries = document.querySelector(".tfs-entries");
        const tfsEntryGap = 20

        const tfsObj = {
            0: {
                "name": "Maria Gomez",
                "message": "The foundation paid Maria Gomez $12,500 - to support her child’s heart surgery.",
                "gender": "f"
            },
            1: {
                "name": "Jonathan Reed",
                "message": "The foundation paid Jonathan Reed $18,000 - to rebuild his home after a devastating fire.",
                "gender": "m"
            },
            2: {
                "name": "Angela Tran",
                "message": "The foundation paid Angela Tran $15,300 - to cover medical expenses for her rare autoimmune condition.",
                "gender": "f"
            },
            3: {
                "name": "Derrick Johnson",
                "message": "The foundation paid Derrick Johnson $20,000 - to fund therapy for his spinal injury recovery.",
                "gender": "m"
            },
            4: {
                "name": "Emily Rivera",
                "message": "The foundation paid Emily Rivera $5,400 - to purchase essential supplies for her newborn twins.",
                "gender": "f"
            },
            5: {
                "name": "Sarah Wilson",
                "message": "The foundation paid Sarah Wilson $37,600 - to continue her education after losing her family’s financial support.",
                "gender": "f"
            },
            6: {
                "name": "Rajesh Kumar",
                "message": "The foundation paid Rajesh Kumar $49,800 - to pay for life-saving cancer treatments.",
                "gender": "m"
            },
            7: {
                "name": "Carlos Martinez",
                "message": "The foundation paid Carlos Martinez $10,000 - to launch his small bakery after being laid off.",
                "gender": "m"
            },
            8: {
                "name": "Amina Ahmed",
                "message": "The foundation paid Amina Ahmed $14,200 - to relocate her family after a natural disaster.",
                "gender": "f"
            },
            9: {
                "name": "Tom Brooks",
                "message": "The foundation paid Tom Brooks $6,700 - to repair his car and get back to work as a delivery driver.",
                "gender": "m"
            },
            10: {
                "name": "Nina Patel",
                "message": "The foundation paid Nina Patel $4,500 - to help her start a small tailoring business.",
                "gender": "f"
            },
            11: {
                "name": "Ahmed Hussein",
                "message": "The foundation paid Ahmed Hussein $28,000 - to fund surgery for his kidney transplant.",
                "gender": "m"
            },
            12: {
                "name": "Laura Evans",
                "message": "The foundation paid Laura Evans $2,700 - to replace her lost teaching supplies after a storm.",
                "gender": "f"
            },
            13: {
                "name": "Peter Wu",
                "message": "The foundation paid Peter Wu $11,500 - to support his recovery from a severe car accident.",
                "gender": "m"
            },
            14: {
                "name": "Fatima Noor",
                "message": "The foundation paid Fatima Noor $19,200 - to help her provide clean water to her village.",
                "gender": "f"
            },
            15: {
                "name": "Sam O’Connor",
                "message": "The foundation paid Sam O’Connor $47,800 - to rebuild his farm after heavy flooding.",
                "gender": "m"
            },
            16: {
                "name": "Grace Kim",
                "message": "The foundation paid Grace Kim $5,000 - to help her cover travel costs for medical treatments.",
                "gender": "f"
            },
            17: {
                "name": "Abdul Rahman",
                "message": "The foundation paid Abdul Rahman $8,400 - to support his education in engineering.",
                "gender": "m"
            },
            18: {
                "name": "Sophia Hernandez",
                "message": "The foundation paid Sophia Hernandez $33,600 - to help her recover from a devastating house fire.",
                "gender": "f"
            },
            19: {
                "name": "Ethan Brown",
                "message": "The foundation paid Ethan Brown $14,300 - to support his physical therapy after a sports injury.",
                "gender": "m"
            }
        }
        
        
        let lastTfsInd = 0
        const elementWidth = 400

        let tfsPlaceCoordinate = tfsEntryGap

        const addContentToSlide = ({message, name, gender}) => {
            const tfsEntry = document.createElement('div')
            tfsEntry.setAttribute('class','tfs-entry')
            
            tfsEntry.innerHTML = `
                <!--double quote mark-->
                <div class="tfs-blue-double-quote-wrapper">
                    <img
                        src="https://res.cloudinary.com/derzcse6f/image/upload/v1732614561/raster_blue_double_quotes_ttdulp.png"
                        alt="Quote mark"
                    />
                </div>
                <!--message-->
                <p><span>${message.split('-')[0]}</span> ${message.split('-')[1]}</p>
                <!--name and avatar-->
                <div class="tfs-avatar-et-name">
                    <div class="tfs-avatar-wrapper">
                        <img
                            src=${
                                gender==='m'?
                                'https://res.cloudinary.com/derzcse6f/image/upload/v1732614563/male_raster_avatar_aobbg4.png':'https://res.cloudinary.com/derzcse6f/image/upload/v1732614562/female_raster_avatar_pbjhtn.png'
                            }
                            alt='avatar'
                        />
                    </div>
                    <div class="tfs-name-wrapper">${name}</div>
                </div>
            `
        
            return tfsEntry;
        }

        const animateElement = ({
            direction
        }) => {
            // get the next element to animate
            const tfsEntry = addContentToSlide({
                message: tfsObj[lastTfsInd].message,
                name: tfsObj[lastTfsInd].name,
                gender: tfsObj[lastTfsInd].gender,
            })

            // increment the lastTfsInd
            lastTfsInd += 1
            // checks that the index doesn't exceed the object's length 
            if(lastTfsInd === Object.keys(tfsObj).length){
                lastTfsInd=0
            }

            // if there's a manual direction, set it
            if(direction){
                if(direction === 'left'){
                    tfsEntry.style.left = tfsPlaceCoordinate + 'px'
                }else if(direction === 'right'){
                    tfsEntry.style.right = tfsPlaceCoordinate + 'px'
                }
            }
        
            // append the element to the parent
            tfsEntries.append(tfsEntry)

            // set a transition on the element
            tfsEntry.style.transitionDuration=`${(wW*20)/1330}s`
            // set a callback when transition ends
            tfsEntry.ontransitionend=(e)=>{
                e.target.remove()
            }

            // fire the transition after 1 second
            setTimeout(()=>{
                direction && direction === 'right' ? 
                tfsEntry.style.transform=`translateX(${wW+400}px)`:
                tfsEntry.style.transform=`translateX(-${wW+400}px)`
            },1000)

            // increment the coordinate to place the next element if there need be
            tfsPlaceCoordinate += (elementWidth + tfsEntryGap)
        }

        // get the width of the parent element
        const tfsEntriesOffsetWidth = tfsEntries.offsetWidth;
        
        // animate an element
        animateElement({
            direction: 'left'
        })
        
        // iterate to place more elements manually
        while((tfsEntriesOffsetWidth-tfsPlaceCoordinate) >= (elementWidth+tfsEntryGap)){
            // animate an element
            animateElement({
                direction: 'left'
            })
        }

        setInterval(()=>{
            animateElement({
                direction: null
            })
        },5000)
    },[])

    return(
        <section className="testimonial-of-fulfillment-section">
            <div className="tfs-sub">
                <div className="tfs-head">
                    {/*tfs tag*/}
                    <div className="tfs-tag">
                        <div>
                            <WhiteStar/>
                        </div>
                        <div>Charity at best</div>
                    </div>

                    <h2>
                        Testimonials of fulfillment
                    </h2>
                </div>

                {/*tfs entries*/}
                <div className="tfs-entries">
                </div>
            </div>
        </section>
    )
}

export default TfsSection;