import React,{ useEffect } from 'react';

// style import
import '../../component_styles/landing/landing_base.css'

// component import
import TopBar from './TopBar';
import DonationSection from './homepage/DonationSection';
import ServiceSection from './homepage/ServiceSection';
import HeroSection from './homepage/HeroSection';
import SuccessStoriesSection from './homepage/SuccessStoriesSection';
import AsideSection from './homepage/AsideSection';
import VolunteerSection from './homepage/VolunteerSection';
import AssistanceSection from './homepage/AssistanceSection';
import TestimonialSection from './homepage/TestimonialSection';
import HeroSection2 from './homepage/HeroSection2';
import LilCtaSection from './homepage/LilCtaSection';
import FoundersSection from './homepage/FounderSection';
import BottomBar from './homepage/BottomBar';
import HomepageBlogSnippet from './homepage/BlogListing';
import TfsSection from './homepage/TfsSection';


const HomePage = () => {

    useEffect(()=>{
        document.title = 'American Native Heart Foundation' 

        document.documentElement.style.backgroundColor = "#fff";
    },[])

    return(
        <div>
            {/*top bar*/}
            <TopBar/>

            {/*hero section*/}
            <HeroSection />

            {/*service section*/}
            <ServiceSection />

            {/* homepage blog listing */}
            <HomepageBlogSnippet/>

            {/*donation section*/}
            <DonationSection />

            {/*success section*/}
            <SuccessStoriesSection />

            {/* aside section */}
            <AsideSection />

            {/*volunteer section*/}
            <VolunteerSection />

            {/*assistance section*/}
            <AssistanceSection />

            {/*testimonial section*/}
            <TestimonialSection />

            {/* Hero2 section */}
            <HeroSection2 />

            {/* Lil cta section */}
            <LilCtaSection />

            {/*testimonials of fulfillment*/}
            <TfsSection/>

            {/* Founders Section*/}
            <FoundersSection />

            {/* Bottom bar*/}
            <BottomBar />
        </div>
    )
}

export default HomePage