
import '../../../component_styles/landing/homepage/founders_section.css'

const FoundersSection = () => {
    const foundersList = [
        {
            'name': 'John martin',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727657283/John-Martins_mjqqcq.png"
        },
        {
            'name': 'Barack Obama',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727657507/Barrack_Obama_gy7ih2.jpg"
        },
        {
            'name': 'Julia Brownley',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1728898666/julia_brownley_ryg1vc.jpg"
        },
        {
            'name': 'Governor Tim Walz',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727657676/Governor_Tim_Walz_v4anf5.jpg"
        },
        {
            'name': 'Tina Liebling',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727657955/tina-liebling_dnf7ju.jpg"
        },
        {
            'name': 'Elizabeth Warren',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727658032/elizabeth-warren_sbreo1.jpg"
        },
        {
            'name': 'Majid M. Padellan',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727658106/MaJid_M._Padellan_mxwsrv.jpg"
        },
        {
            'name': 'Mark Takano',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1728898882/mark_takano_u4sfwy.jpg"
        },
        {
            'name': 'Ed krassenstein and Brian krassenstein',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1727658231/Ed_krassenstein_and_Brian_krassenstein_vtodpl.jpg"
        },
        {
            'name': 'Diana Degette',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1728899039/diana_degette_haik5n.jpg"
        },
        {
            'name': 'Joanne Carducci',
            "image_url": "https://res.cloudinary.com/derzcse6f/image/upload/v1732786354/jojanne_carduci_cwautv.jpg"
        },
    ]

    return(
        <section className="founders">
            <div className="smaller-note-wrapper">
                <div className="smaller-note">MEET THE FOUNDERS</div>
            </div>
            <div className="service-heading heading-text">
                Great founders
            </div>
            <div className="founders-gallery">
                {foundersList.map((entry,index)=>(
                    <div key={index} className="founders-entry">
                        <div 
                            className="slide-photo"
                            style={entry.name === 'John martin'?{
                                background: 'linear-gradient(90deg, #e2e2e2ff, #f2d2d3ff)'
                            }:{}}
                        >
                            <img
                                src={entry.image_url}
                                alt={entry.name}
                                style={entry.name==='Barack Obama'?{objectFit:'fill'}:{}}
                            />
                        </div>
                        <div className="slide-name">
                            {entry.name}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default FoundersSection;